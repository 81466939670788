import Image from '@next/image'
import { Link } from 'elements/Link/Link'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageType } from 'typings/image'

type Props = {
  className?: string
  children?: ReactNode
  image?: ImageType
  name: string
  short_description: string
  url_key: string
  hmv_number: string
  filter?: Array<string>
  hmv_label: string
  neuprodukt?: boolean
}

export const ProductCard = ({
  className = '',
  name,
  short_description,
  url_key,
  image,
  hmv_number,
  children,
  filter,
  hmv_label,
  neuprodukt,
}: Props) => {
  const { t } = useTranslation('common')

  return (
    <div className={`flex h-full flex-col border border-gray p-4 pb-4 pt-2 ${className}`}>
      <figure>
        <Link href={url_key} className="relative mb-2 block">
          {neuprodukt ? (
            <div className="absolute top-1 right-2 z-10 bg-red p-2 uppercase text-white md:top-2 md:right-0">
              {t('pdp.new')}
            </div>
          ) : null}
          {image ? (
            <Image src={image.url} alt={image.alt} width={1} height={1} layout="responsive" />
          ) : (
            <div className="relative pb-[100%] opacity-30">
              <Image
                src="/assets/DJOisnowEnovis__stacked.jpg"
                alt="DJO is now Enovis"
                fill
                style={{ objectFit: 'contain' }}
              />
            </div>
          )}
        </Link>
        <figcaption>
          <h2 className="mb-2 text-base text-red md:text-xl">
            <Link
              href={url_key}
              className="font-bold leading-5 tracking-wide md:leading-7 md:hover:underline"
            >
              {name}
            </Link>
          </h2>
          {hmv_number ? (
            <p className="mb-2">
              <span className="font-bold">{hmv_label}</span> {hmv_number}
            </p>
          ) : null}
          <p className="mb-2 text-sm font-medium text-black-light md:mb-4">{short_description}</p>
        </figcaption>
      </figure>
      {children}
    </div>
  )
}
