import { ProductOverviewVariant } from 'typings/api'
import { ProductCard } from 'components/ProductCard/ProductCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Swiper as SwiperType } from 'swiper'
import { Navigation } from 'swiper'
import { NavigationOptions } from 'swiper/types'
import { Headline, HeadlineType } from 'elements/Headline/Headline'
import { Link } from 'elements/Link/Link'
import { SlideNav } from 'elements/SlideNav/SlideNav'
import { useMedia } from 'hooks/useMedia'
import { useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'
import clsx from 'clsx'

type Props = { products: ProductOverviewVariant[]; title?: string }

export const ProductSlider = ({ products, title }: Props) => {
  const prevRef = useRef<HTMLButtonElement>()
  const nextRef = useRef<HTMLButtonElement>()
  const swiperRef = useRef<SwiperType>()
  const [isEnd, setIsEnd] = useState(false)
  const [isBeginning, setIsBeginning] = useState(false)
  const isDesktop = useMedia('md')
  const { t } = useTranslation('common')

  if (!products?.length) {
    return null
  }

  return (
    <section className="product-slider mx-4 mb-10 grid grid-cols-1 grid-rows-[auto,auto,auto] md:mx-20 md:grid-cols-2">
      {title ? (
        <Headline type={HeadlineType.h2} className={`mb-6 inline-block pb-2 text-red sm:mb-1`}>
          {title}
        </Headline>
      ) : null}
      {isBeginning && isEnd ? null : (
        <div className="row-start-3 flex flex-row justify-self-end pb-2 md:col-start-2 md:row-start-1">
          <SlideNav
            clickPrev={() => swiperRef.current.slidePrev()}
            clickNext={() => swiperRef.current.slideNext()}
            disablePrev={isBeginning}
            disableNext={isEnd}
            className={clsx(
              'items-center',
              products.length <= (isDesktop ? 4 : 2) ? 'hidden' : 'md:inline-flex',
            )}
          />
        </div>
      )}

      <div
        className={clsx(
          'row-start-2 mb-4 border-gray pb-0 md:col-span-2 md:border-b-0 md:border-t md:pt-10',
          products.length > 2 ? 'border-b ' : '',
          products.length < 4 ? 'no-slide' : '',
        )}
      >
        <Swiper
          modules={[Navigation]}
          slidesPerView={products.length <= 2 ? products.length : 1.35}
          spaceBetween={16}
          breakpoints={{
            768: { slidesPerView: products.length < 4 ? products.length : 4, spaceBetween: 32 },
          }}
          navigation={{ nextEl: nextRef.current, prevEl: prevRef.current }}
          onBeforeInit={(swiper) => {
            ;(swiper.params.navigation as NavigationOptions).prevEl = prevRef.current
            ;(swiper.params.navigation as NavigationOptions).nextEl = nextRef.current

            swiperRef.current = swiper
          }}
          onAfterInit={(swiper) => {
            setIsBeginning(swiper.isBeginning)
            setIsEnd(swiper.isEnd)
          }}
          onSlideChange={(swiper) => {
            setIsBeginning(swiper.isBeginning)
            setIsEnd(swiper.isEnd)
          }}
        >
          {products.map((product) => (
            <SwiperSlide key={product.uuid}>
              <ProductCard
                className="h-full w-full justify-between"
                name={product.name}
                short_description={product.beschreibung}
                image={product.bild}
                url_key={product.path}
                hmv_number={product.nummer}
                hmv_label={product.label_nummer}
              >
                <Link
                  href={product.path}
                  className="bg-red p-4 text-center uppercase leading-4 tracking-wider text-white"
                >
                  {t('pdp.To the Product')}
                </Link>
              </ProductCard>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}
