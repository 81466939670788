import { Spinner } from 'components/Spinner/Spinner'
import { usePIMData } from 'hooks/usePIMData'
import { selectContentModule } from 'lib/selectContentModule'
import { NextSeo } from 'next-seo'
import invariant from 'tiny-invariant'
import { PageType } from 'typings/api'
import { Breadcrumb } from 'elements/Breadcrumb/Breadcrumb'
import { ProductSlider } from '../ProductDetailPage/components/ProductSlider'
import { Fragment } from 'react'

export type Props = { identifier: string }

export const CMSPage = ({ identifier }: Props) => {
  const { data, isLoading, error } = usePIMData<PageType>(identifier)

  if (isLoading) {
    return <Spinner fullsize />
  }

  if (error && process.env.NODE_ENV === 'development' && error instanceof Error) {
    return <div>{error.message}</div>
  } else if (error) {
    return <div>Something went wrong</div>
  }
  try {
    if (data) {
      invariant(data.type === 'content_page', "Type doesn't match content page")
      return (
        <>
          <NextSeo
            title={data.title}
            description={data.meta_description}
            additionalMetaTags={[{ name: 'title', content: data.meta_title }]}
          />

          {data.main.content_modules.map((mod, i) => {
            return (
              <Fragment key={`${mod.type}-${i}`}>
                {i === (data.main.content_modules[0].type.includes('stage') ? 1 : 0) &&
                  data.breadcrumb && data.breadcrumb.length >= 1 &&
                  (
                    <Breadcrumb
                      breadcrumbData={data.breadcrumb}
                      pageTitle={data?.title}
                      className="mx-auto max-w-[60rem] xl:max-w-text"
                    />
                  )}
                {selectContentModule(mod, i)}
              </Fragment>
            )
          })}

          {data.main.products ? (
            <ProductSlider products={data.main.products} title={data.main.products_title} />
          ) : null}
        </>
      )
    }
  } catch (e) {
    console.error(e.message)
    return null
  }
}
